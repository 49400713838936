import React from 'react';
import '../styles/PrivacyPolicy.css';

function PrivacyPolicy() {
  return (
    <div id="privacy-policy" className="privacy-policy futuristic">
      <h1 className="title">Privacy Policy for HMP Engineering Solutions</h1>
      <p className="last-updated"><strong>Last Updated:</strong> December 2024</p>

      <section className="section">
        <p>
          At <span className="highlight">HMP Engineering Solutions</span>, your privacy is our priority. This policy outlines 
          how we handle your data when you engage with us. We encourage you to review it carefully.
        </p>
      </section>

      <h2 className="section-title">1. Information We Collect</h2>
      <p>We gather the following types of data:</p>
      <ul className="data-list">
        <li><strong>Personal Info:</strong> Name, email, phone number.</li>
        <li><strong>Usage Data:</strong> IP address, device type, browser, and site interactions.</li>
        <li><strong>Cookies:</strong> Small files to enhance your experience and track activity.</li>
      </ul>

      <h2 className="section-title">2. How We Use Your Information</h2>
      <ul className="usage-list">
        <li>To enhance services and support.</li>
        <li>To personalize your experience.</li>
        <li>To improve website functionality and performance.</li>
        <li>To comply with legal requirements.</li>
      </ul>

      <h2 className="section-title">3. Cookies & Tracking</h2>
      <p>
        Cookies optimize your experience. Adjust your browser settings if needed, but note some features may be impacted.
      </p>

      <h2 className="section-title">4. Sharing Your Data</h2>
      <p>
        We do not sell your data. Information is shared only with trusted partners under strict confidentiality.
      </p>

      <h2 className="section-title">5. Data Security</h2>
      <p>
        Your data's security is vital. We employ robust measures but acknowledge that no system is infallible.
      </p>

      <h2 className="section-title">6. Your Rights</h2>
      <ul className="rights-list">
        <li><strong>Access:</strong> Request your data.</li>
        <li><strong>Correct:</strong> Update inaccuracies.</li>
        <li><strong>Delete:</strong> Erase data in specific situations.</li>
        <li><strong>Object:</strong> Stop marketing use.</li>
        <li><strong>Restrict:</strong> Limit data processing.</li>
        <li><strong>Transfer:</strong> Move your data elsewhere.</li>
      </ul>

      <h2 className="section-title">7. External Links</h2>
      <p>
        Third-party links on our site are not covered by this policy. Please review their policies before engaging.
      </p>

      <h2 className="section-title">8. Children’s Privacy</h2>
      <p>
        Our services are not for users under 13. We promptly delete any such data if discovered.
      </p>

      <h2 className="section-title">9. Updates to This Policy</h2>
      <p>
        This policy may change. We recommend checking regularly for updates.
      </p>

      <h2 className="section-title">10. Contact Us</h2>
      <p>
        Questions? Reach out at: <a href="mailto:info@hmpengineering.co.za" className="email-link">info@hmpengineering.co.za</a>
      </p>
    </div>
  );
}

export default PrivacyPolicy;
