import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ShopContext from '../context/shop/shopContext';
import { Container, Dropdown } from 'react-bootstrap';
import { FaShareAlt, FaFacebook, FaWhatsapp, FaLinkedin, FaInstagram } from 'react-icons/fa';
import xLogo from '../assets/x.png';  
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase/config'; 

const PostDetails = () => {
  const { id } = useParams();
  const { posts, getPosts } = useContext(ShopContext);
  const [post, setPost] = useState(null);
  const [views, setViews] = useState(0);

  const formatViews = (views) => {
    return views.toLocaleString(); // Format views with commas
  };

    const [showExact, setShowExact] = useState(false);
  
    const handleClick = () => {
      setShowExact(!showExact);
    };

    useEffect(() => {
      const fetchPostDetails = async () => {
        const docRef = doc(db, 'posts', id);
        const docSnap = await getDoc(docRef);
    
        if (docSnap.exists()) {
          setPost(docSnap.data());
          
          // Check sessionStorage for existing view
          const viewedPosts = JSON.parse(sessionStorage.getItem('viewedPosts')) || {};
          if (!viewedPosts[id]) {
            updateViews(docRef, docSnap.data().views); // Increment views
            viewedPosts[id] = true; // Mark as viewed
            sessionStorage.setItem('viewedPosts', JSON.stringify(viewedPosts));
          } else {
            setViews(docSnap.data().views); // Only set views without incrementing
          }
        } else {
          // Store post data in Firestore if not already present
          const selectedPost = posts.find((p) => p.id === id);
          setPost(selectedPost);
          await setDoc(docRef, { ...selectedPost, views: 1 });
          setViews(1);
        }
      };
    
      if (posts?.find((p) => p.id === id)) {
        fetchPostDetails();
      } else {
        getPosts(); 
      }
    }, [id, posts, getPosts]);
    
    const updateViews = async (docRef, currentViews) => {
      const newViews = currentViews + 1;
      await updateDoc(docRef, { views: newViews });
      setViews(newViews);
    };
  const sharePost = (platform) => {
    if (!post) return;
    
    const shareUrl = `${window.location.origin}/post/${post.id}`;
    const text = `Check out this post: ${post.title}`;
    
    let url = '';
    if (platform === 'facebook') {
      url = `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`;
    } else if (platform === 'x') {
        url = `https://x.com/intent/tweet?url=${shareUrl}&text=${text}`;
    } else if (platform === 'whatsapp') {
      url = `https://api.whatsapp.com/send?text=${text} ${shareUrl}`;
    } else if (platform === 'linkedin') {
      url = `https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`;
    }
    else if (platform === 'instagram') {
        // Instagram does not allow direct sharing via URL. Instead, we'll use an Instagram deep link.
        // Instagram deep links only work with media URLs e.g., images/videos.
        // Instagram requires the app to be installed for sharing images, so we use a generic Instagram URL.
        url = `https://www.instagram.com/create/style/`;
      }
    window.open(url, '_blank');
  };

  if (!post) {
    return <div>Loading...</div>;
  }

  // Split description into paragraphs creatively
  const paragraphs = post.description
    .split(/\.\s+|\n\n/) // Split by period followed by space or double newline
    .map((paragraph, index) => paragraph.trim())
    .filter((paragraph) => paragraph.length > 0);

  return (
    <Container>
      <h1 style={{ marginTop: '40px' }}>{post.title}</h1>
      <p>{post.date} | {post.category}</p>
      <p>
      <span
        style={{ cursor: 'pointer', textDecoration: 'none' }}
        onClick={handleClick}
        title={showExact ? 'Click to show formatted view' : 'Click to show exact number'}
      >
        {showExact ? views.toLocaleString() : formatViews(views)}
      </span>
      {' '}<strong>Views</strong>
    </p>      
    <div
        style={{
          backgroundImage: `url(${post.image})`,
          width: '100%',
          height: 350,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          borderRadius: 5,
          marginBottom: '20px',
        }}
      />

      <div
        style={{
          fontSize: '1.1rem',
          lineHeight: '1.6',
          padding: '10px 15px',
          backgroundColor: '#f9f9f9',
          borderRadius: '8px',
          color: '#333',
        }}
      >
        {paragraphs.map((paragraph, index) => (
          <p key={index} style={{ marginBottom: '1.2em' }}>
            {paragraph}.
          </p>
        ))}
      </div>

      <Dropdown style={{ marginTop: '20px', alignContent:'center' }}>
        <Dropdown.Toggle variant="light" id="share-dropdown" style={{ borderRadius: '50%', backgroundColor:'#40FF40' }}>
          <FaShareAlt /> Share
        </Dropdown.Toggle>
        <Dropdown.Menu>
        <Dropdown.Item onClick={() => sharePost('instagram')}>
          <FaInstagram style={{ color: '#E4405F', marginBottom: '3px' }} /> Share on Instagram
         </Dropdown.Item>
          <Dropdown.Item onClick={() => sharePost('facebook')}>
            <FaFacebook style={{ color: '#3b5998',marginBottom: '3px' }} /> Share on Facebook
          </Dropdown.Item>
          <Dropdown.Item onClick={() => sharePost('x')}>
            <img 
              src={xLogo} 
              alt="X Logo" 
              style={{ width: '15px', height: '15px', marginBottom: '2px', borderRadius: '100px', marginRight:'7px' }} 
            />
            Share on X
          </Dropdown.Item>
          <Dropdown.Item onClick={() => sharePost('whatsapp')}>
            <FaWhatsapp style={{ color: '#25D366', marginBottom: '3px' }} /> Share on WhatsApp
          </Dropdown.Item>
          <Dropdown.Item onClick={() => sharePost('linkedin')}>
            <FaLinkedin style={{ color: '#0077b5', marginBottom: '3px' }} /> Share on LinkedIn
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Container>
  );
};

export default PostDetails;
